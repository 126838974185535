<template>
  <div class="article-add">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dashboard/article' }">文章管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加文章</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-row>
        <el-form :model="addForm" :rules="addRules" ref="addRef" label-width="100px" class="add-form">
          <el-form-item label="文章标题" prop="title">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="文章描述" prop="article_desc">
            <el-input type="textarea" placeholder="请输入文章描述" v-model="addForm.article_desc" maxlength="200" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="文章缩略图" prop="img">
            <upload :url="addForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
          <el-form-item label="文章内容" prop="content">
            <QuillBar v-model="addForm.content"></QuillBar>
          </el-form-item>
          <el-form-item label="文章状态" prop="status">
            <el-radio-group v-model="addForm.status">
              <el-radio :label="1" >待审核</el-radio>
              <el-radio :label="2" >正常</el-radio>
              <el-radio :label="3">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('addRef')">立即创建</el-button>
            <el-button @click="backUrl">返回</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
import QuillBar from '../common/QuillBar'
export default {
  name: 'ArticleAdd',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      addForm: {
        title: '',
        article_desc: '',
        img: '',
        content: '',
        status: 1
      },
      addRules: {
        title: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' }
        ],
        article_desc: [
          { required: true, message: '请输入文章描述', trigger: 'blur' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入文章内容', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择文章状态', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    backUrl () {
      this.$router.push('/dashboard/article')
    },
    // 提交表单
    async submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/article/0', this.addForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.$router.push('/dashboard/article')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  padding: 60px;
}
</style>
